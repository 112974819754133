import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment  from 'moment';

const state = {
    id: 0,
    facultad: '',
    carrera: '',
    tipoCarrera: '',
    periodo: 0,
    fechaDesde: '1900-01-01',
    fechaHasta: '1900-01-01',

    cuentas: [],
    totalIngresos: 0,
    totalEgresos: 0,
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,
};

const mutations = {
    updateField,

    'INICIALIZAR_DATOS'(state, presupuestoId) {
        state.id = presupuestoId;
        state.facultad = '';
        state.carrera = '';
        state.tipoCarrera = '';
        state.periodo = '';
        state.fechaDesde = '1900-01-01';
        state.fechaHasta = '1900-01-01';
    
        state.cuentas = [];
        state.totalIngresos = 0;
        state.totalEgresos = 0;
        state.paginaActual = 1;
        state.totalRegistros = 0;
    },
    'SET_DATOS'(state, presupuesto) {
        state.facultad = presupuesto.facultad;
        state.carrera = presupuesto.carrera;
        state.tipoCarrera = presupuesto.tipoCarrera;
        state.periodo = presupuesto.periodo;
        state.fechaDesde = moment(presupuesto.fechaDesde).format('YYYY-MM-DD');
        state.fechaHasta = moment(presupuesto.fechaHasta).format('YYYY-MM-DD');
        
        state.cuentas = presupuesto.cuentas;
        state.totalRegistros = state.cuentas.length;
        if (state.registrosPorPagina != 0) {
            state.totalPaginas = Math.ceil(state.totalRegistros / state.registrosPorPagina);
        }

        if (state.cuentas) {
            state.totalIngresos = state.cuentas.reduce((total, cuenta) => total + parseFloat(cuenta.ingresos), 0);
            state.totalEgresos = state.cuentas.reduce((total, cuenta) => total + parseFloat(cuenta.egresos), 0);
        }
    },
};

const actions = {
    getDatos ( { commit }, presupuestoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS', presupuestoId);

            if (presupuestoId) {
                apiAxios.get(`presupuestos/${presupuestoId}/cuentas` ) 
                    .then(res => {
                        commit('SET_DATOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }
        })
    },
};

const getters = {
    getField,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}